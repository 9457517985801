import {tap} from 'rxjs/operators';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
    GetInvoiceHistoryResponse,
    GetApiResponse,
    GetYardiTransactions,
    GetYardiBuildings,
    ThirdPartyList,
    ThirdParty,
    countInvalidPhoneEmail,
} from '../utils/modals/response/response.modal';

import {GetYardiConfig} from '../utils/modals/response/response.modal';
import {API_URL} from 'src/app/pathConfig';
import {KycUserInfo} from '../pages/kyc/kyc.constant';

@Injectable({providedIn: 'root'})
export class ApiResponseService {
    /** base URL which will be same in every API call */
    private readonly baseUrl = `${API_URL}`;

    /** constructor */
    constructor(private readonly http: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * get the unit
     */
    getUnits(page: number, limit: number, searchQuery: string, buildingId: string, status: string, orderBy: string) {
        if (buildingId) {
            return this.http.get<GetApiResponse>(`${this.baseUrl}/unit/getUnits`, {
                params: {page, limit, searchQuery, buildingId, status, orderBy},
            });
        } else {
            return this.http.get<GetApiResponse>(`${this.baseUrl}/unit/getUnits`, {
                params: {page, limit, searchQuery, status, orderBy},
            });
        }
    }

    /**
     * get the building
     */
    getBuildings(
        page: number,
        limit: number,
        searchQuery: string,
        bankAccountId: string,
        status: string,
        orderBy: string,
        hasIncompleteAddress: boolean
    ) {
        if (bankAccountId) {
            return this.http.get<GetApiResponse>(`${this.baseUrl}/building/getBuildings`, {
                params: {page, limit, searchQuery, bankAccountId, status, orderBy, hasIncompleteAddress},
            });
        } else {
            return this.http.get<GetApiResponse>(`${this.baseUrl}/building/getBuildings`, {
                params: {page, limit, searchQuery, status, orderBy, hasIncompleteAddress},
            });
        }
    }

    getBuildingsForKyc(
        page: number,
        limit: number,
        searchQuery: string,
        bankAccountId: string,
        status: string,
        orderBy: string,
        hasIncompleteAddress: boolean,
        isYardiProperty: boolean
    ) {
        if (bankAccountId) {
            return this.http.get<GetApiResponse>(`${this.baseUrl}/building/getBuildings`, {
                params: {
                    page,
                    limit,
                    searchQuery,
                    bankAccountId,
                    status,
                    orderBy,
                    hasIncompleteAddress,
                    isYardiProperty,
                },
            });
        } else {
            return this.http.get<GetApiResponse>(`${this.baseUrl}/building/getBuildings`, {
                params: {
                    page,
                    limit,
                    searchQuery,
                    status,
                    orderBy,
                    hasIncompleteAddress,
                    isYardiProperty,
                },
            });
        }
    }
    /*getBankAccounts*/
    getBankAccounts(page: number, limit: number, searchQuery: string, status: string, orderByr: string) {
        return this.http.get<GetApiResponse>(`${this.baseUrl}/bank/getBankAccounts`, {
            params: {page, limit, searchQuery, status, orderByr},
        });
    }

    /*getManagers*/
    getManagers(page: number, limit: number, searchQuery: string) {
        return this.http.get<GetApiResponse>(`${this.baseUrl}/manager/getManagers`, {
            params: {page, limit, searchQuery},
        });
    }

    /*getResidents*/
    getResidents(
        page: number,
        limit: number,
        buildingId: string,
        bankAccountId: string,
        unitId: string,
        payNumber: string,
        searchQuery: string,
        orderBy: string,
        status: string,
        removed: boolean
    ) {
        const params: any = {
            page,
            limit,
            buildingId,
            bankAccountId,
            unitId,
            payNumber,
            searchQuery,
            orderBy,
            status,
            removed,
        };

        // Remove keys with null, undefined, or empty values
        const filteredParams = Object.keys(params)
            .filter((key) => params[key] !== null && params[key] !== undefined && params[key] !== '')
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});

        return this.http.get<GetApiResponse>(`${this.baseUrl}/resident/getResidents`, {
            params: filteredParams,
        });
    }

    /* getBilling*/
    getBilling(page: number, limit: number) {
        return this.http.get<GetInvoiceHistoryResponse>(`${this.baseUrl}/company/tpInvoiceHistory`, {
            params: {page, limit},
        });
    }

    /*getTransactions*/
    getTransactions(
        page: number,
        limit: number,
        startDate: string,
        endDate: string,
        bankAccountId: string,
        buildingId: string,
        unitId: string,
        searchQuery: string,
        payNumber: string,
        orderBy: string
    ) {
        const params: any = {
            page,
            limit,
            startDate,
            endDate,
            bankAccountId,
            buildingId,
            unitId,
            searchQuery,
            payNumber,
            orderBy,
        };

        // Remove keys with null, undefined, or empty values
        const filteredParams = Object.keys(params)
            .filter((key) => params[key] !== null && params[key] !== undefined && params[key] !== '')
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});

        return this.http.get<GetApiResponse>(`${this.baseUrl}/transaction/getTransactions`, {
            params: filteredParams,
        });
    }

    /*getSentNotices*/
    getSentNotices(page: number, limit: number) {
        return this.http.get<GetApiResponse>(`${this.baseUrl}/notice/getSentNotices`, {
            params: {page, limit},
        });
    }
    /*
    getYardiConfig
    */
    getYardiConfigs(status: string) {
        return this.http.get<GetYardiConfig>(`${this.baseUrl}/yardi/getYardiConfigs`, {
            params: {status},
        });
    }

    /*getYardiTransactions*/
    getYardiTransactions(
        page: number,
        limit: number,
        buildingId: string,
        date: string,
        status: string,
        yardiIntegrationId: string
    ) {
        return this.http.get<GetYardiTransactions>(`${this.baseUrl}/yardi/getYardiTransactions`, {
            params: {page, limit, buildingId, date, status, yardiIntegrationId},
        });
    }

    addKycInfoForCustomer(value: KycUserInfo): Observable<any> {
        return this.http.put<any>(`${this.baseUrl}/kyc/information`, value);
    }

    /*getUnpushedTransactions*/
    getUnpushedTransactions(page: number, limit: number, yardiIntegrationId: string) {
        return this.http.get<GetYardiTransactions>(`${this.baseUrl}/yardi/getUnpushedTransactions`, {
            params: {page, limit, yardiIntegrationId},
        });
    }

    /*downloadLogs*/
    downloadYardiLogs(id: string, logType: string) {
        return this.http.get(`${this.baseUrl}/yardi/getXmlLog`, {
            params: {id, logType},
            observe: 'response',
            responseType: 'blob' as 'json',
        });
    }

    /*getYardiBuildings*/
    getYardiBuildings(status: string) {
        return this.http.get<GetYardiBuildings>(`${this.baseUrl}/yardi/getYardiBuildings`, {
            params: {status},
        });
    }

    /**
     * Sends KYC details to the server.
     *
     * @param info - The KYC details to send.
     * @returns A promise that resolves to the server response.
     */
    sendKycDetails(info: any) {
        return firstValueFrom(this.http.put<any>(`${this.baseUrl}/kyc`, info));
    }

    /**
     * Sends KYC (Know Your Customer) information to the server.
     *
     * @param personalInfo - The personal information of the user.
     * @param companyInfo - The company information of the user.
     * @param address - The address information of the user.
     * @param mailingAddress - The mailing address information of the user.
     * @returns A promise that resolves to the server response.
     */
    sendKycInformation(personalInfo: any, companyInfo: any, address: any, companyType?: string, mailingAddress?: any) {
        if (companyType === '1')
            return this.http.put<any>(`${this.baseUrl}/kyc/information`, {
                personalInfo,
                address,
            });
        else {
            return this.http.put<any>(`${this.baseUrl}/kyc/information`, {
                companyInfo,
                address,
                mailingAddress,
            });
        }
    }

    /**
     * Submits KYC (Know Your Customer) information.
     *
     * @returns {Observable<any>} An observable that emits the response from the API.
     */
    submitKycInformation() {
        return this.http.post<any>(`${this.baseUrl}/kyc/submit`, {});
    }

    /**
     * Submits the company metadata along with company real estate and other real estate type.
     *
     * @param {any} companyMetaData - The company metadata to be submitted.
     * @param {any} companyRealEstate - The company real estate to be submitted.
     * @param {any} otherRealEstateType - The other real estate type to be submitted.
     * @returns {Observable<any>} - An observable that represents the HTTP POST request.
     */
    submitCompanyMetadata(companyMetaData, companyRealEstate) {
        return this.http.put<any>(`${this.baseUrl}/kyc/companyMetaData`, {companyMetaData, companyRealEstate});
    }

    /**
     * Retrieves stakeholder information from the API.
     * @returns {Observable<any>} An observable that emits the stakeholder information.
     */
    getStakeholderInformation() {
        return this.http.get<any>(`${this.baseUrl}/kyc/stakeholders`);
    }
    /**
     * Retrieves the KYC details from the API.
     * @returns An Observable that emits the KYC details.
     */
    getKycDetails() {
        return this.http.get<any>(`${this.baseUrl}/kyc`);
    }
    /**
     * Retrieves the stakeholder designation types from the API.
     * @returns An Observable that emits the stakeholder designation types.
     */
    getStakeHoldersDesignation() {
        return this.http.get<any>(`${this.baseUrl}/kyc/stakeholderDesignationTypes`);
    }

    /**
     * Submits stakeholder information.
     *
     * @param body - The stakeholder information to be submitted.
     * @returns A promise that resolves to the API response.
     */
    submitStakeHoldersinfo(body) {
        return this.http.put<any>(`${this.baseUrl}/kyc/stakeholder`, body);
    }
    /**
     * Retrieves the list of company types from the API.
     * @returns An observable that emits the API response containing the company types.
     */
    getCompanyTypes() {
        return this.http.get<any>(`${this.baseUrl}/kyc/companyTypes`);
    }

    /**
     * Retrieves the business types from the API.
     * @returns {Observable<any>} An observable that emits the API response.
     */
    getBuinessTypes() {
        return this.http.get<any>(`${this.baseUrl}/kyc/businessTypes`);
    }

    /**
     * Retrieves the KYC base details from the API.
     * @returns An Observable that emits the response containing the KYC base details.
     */
    getKycBaseDetails(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/kyc/baseDetails`);
    }

    /**
     * Submits KYC details to the server.
     * @returns {Observable<any>} An observable that emits the server response.
     */
    submitKycDetails() {
        return this.http.post<any>(`${this.baseUrl}/kyc/submit`, {});
    }

    /**
     * Retrieves the list of employment sectors for KYC (Know Your Customer).
     * @returns {Observable<any>} An observable that emits the HTTP response containing the employment sectors.
     */
    getKycEmploymentSector() {
        return this.http.get<any>(`${this.baseUrl}/kyc/employmentSectors`);
    }

    /**
     * Retrieves the list of employment sectors from the API.
     * @returns {Observable<any>} An observable that emits the API response.
     */
    getEmploymentSectors() {
        return this.http.get<any>(`${this.baseUrl}/kyc/employmentSectors`);
    }

    /**
     * Retrieves the accounting software types from the server.
     * @returns An Observable that emits the accounting software types.
     */
    getAccountingSoftwareTypes() {
        return this.http.get<any>(`${this.baseUrl}/kyc/accountingSoftwareTypes`);
    }

    /**
     * Retrieves the list of coop association types from the server.
     * @returns {Observable<any>} An observable that emits the response from the server.
     */
    getCoopAssociationTypes() {
        return this.http.get<any>(`${this.baseUrl}/kyc/coopAssociationTypes`);
    }

    /**
     * Retrieves KYC information from the API.
     * @returns An Observable that emits the KYC information.
     */
    getkycInfo() {
        return this.http.get<any>(`${this.baseUrl}/kyc/info`);
    }

    /**
     * Retrieves KYC information from the API.
     * @returns An Observable that emits the KYC information.
     */
    getCompanyMetadata() {
        return this.http.get<any>(`${this.baseUrl}/kyc/companyMetaData`);
    }

    /**
     * Performs an identity check using Trustmatic.
     * @returns {Observable<any>} The HTTP response containing the result of the identity check.
     */
    idvCheck() {
        return this.http.post<any>(`${this.baseUrl}/idv/idvCheck`, {});
    }

    /**
     * Performs anti money laundering check for the company.
     * @returns {Observable<any>} The response from the API call.
     */
    amlCheck() {
        return this.http.post<any>(`${this.baseUrl}/aml/amlData`, {});
    }

    /**
     * Deletes a document by its ID.
     * @param {string} documentId - The ID of the document to delete.
     * @returns {Observable<any>} - An observable that emits the response from the server.
     */
    deleteDocument(documentId) {
        return this.http.delete<any>(`${this.baseUrl}/kyc/document/${documentId}`);
    }

    /**
     * Sends documents to the server.
     *
     * @param {string} document - The file path of the document to send.
     * @param {string} type - The ID of the KYC document type.
     * @returns {Observable<any>} - An observable that represents the HTTP POST request.
     */
    sendDocuments(document, type) {
        return this.http.post<any>(`${this.baseUrl}/kyc/document`, {
            filePath: document,
            kycDocumentTypeId: type,
        });
    }

    getThirdPartyList() {
        return this.http.get<any>(`${this.baseUrl}/third-party-accounts/getDetails`, {});
    }

    getThirdPartyById(thirdPartyDetailId: string) {
        return this.http.get<any>(`${this.baseUrl}/third-party-accounts/getSingleThirdPartyEntity`, {
            params: {thirdPartyDetailId},
        });
    }

    /**
     * Retrieves the list of nationalities from the API.
     * @returns An observable that emits the response containing the list of nationalities.
     */
    getNationalities() {
        return this.http.get<any>(`${this.baseUrl}/select/nationalities`);
    }

    getIdvCheckStatus() {
        return this.http.get<any>(`${this.baseUrl}/idv/statusByUserId`);
    }

    /*getCountInvalidPhoneEmail*/
    getCountInvalidPhoneEmail(
        buildingId: string,
        bankAccountId: string,
        unitId: string,
        payNumber: string,
        searchQuery: string,
        transmissionMethod: string
    ) {
        return this.http.get<countInvalidPhoneEmail>(`${this.baseUrl}/resident/countInvalidPhoneEmail`, {
            params: {buildingId, bankAccountId, unitId, payNumber, searchQuery, transmissionMethod},
        });
    }

    getBrandingDetails(companyId) {
        return this.http.get<any>(`${this.baseUrl}/company-setting/company/${companyId}`);
    }

    stakeholdersAcknowledgement() {
        return this.http.put<any>(`${this.baseUrl}/kyc/stakeholdersAckTimestamp`, {});
    }
    getKycAccountStatus() {
        return this.http.get<any>(`${this.baseUrl}/kyc/accountStatus`);
    }

    restoreResident(residentId: string) {
        return this.http.patch<any>(`${this.baseUrl}/resident/${residentId}/restore`, {residentId});
    }
}
